/**** Breakpoints ****/
$mobile: 360px;
$mobileToPc: 768px;

/**** Colors ****/
$black: #000000;
$elm: #187582;
$easternBlue: #20AABC;
$chateauGreen: #2DB84B;
$thundora: #484848;
$shakespeare: #4BC6CA;
$cornflowerBlue: #519CE9;
$doveGray: #666666;
$gersey: #D3DCDD;
$whiteIce: #D9EEF8;
$tonysPink: #E49494;
$catskillWhite: #EFF5F8;
$white: #FFFFFF;
