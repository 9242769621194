.button {
    background: $white;
    font-weight: 700;
    color: $easternBlue;
    font-size: 1.8rem;
    border: 2px solid $easternBlue;
    border-radius: 4px;
    box-shadow: none;
    &:hover {
        color: $white;
        background: $easternBlue;
    }
    &.large {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
}
.finalButton {
    width: auto;
    font-size: 1.6rem;
    color: $easternBlue;
    background-color: $white;
    border: solid 2px $easternBlue;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-radius: 4px;
    font-weight: 700;
    &:hover {
        background-color: $easternBlue;
        color: $white;
    }
    &:disabled {
        color: $doveGray;
        border-color: $doveGray;
    }
}
