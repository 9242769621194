@import "../config/variables";

html { font-size: 62.5%; font-weight: 300;}

/*** Update for Semantic ***/
body {
    color: $thundora;
    font-size: 1.6rem;
    line-height: normal;
    .ui.grid>.row {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
    .center {
        text-align: center;
    }
}

.ui.container.custom-container {
    max-width: 960px !important;
}

.nobr {
    white-space: nowrap;
}

.content-top {
    min-height: calc(100vh - 120px);
    @media only screen and (min-width: $mobileToPc) {
        min-height: calc(100vh - 116px);
    }
}

/********** Footer **************/
footer {
    background: $whiteIce;
    .ui.grid > .row {
        padding-bottom: 0;
        &:last-child {
            padding-bottom: 1.6rem;
        }
    }
    a {
        color: $black;
    }
    .contact-link {
        font-size: 1.8rem;
        a {
            font-weight: 400;
        }
    }
    .footer-links {
        font-size: 1.5rem;
        @media only screen and (min-width: $mobileToPc) {
            font-size: 1.2rem;
        }
    }
    .footer-copyright {
        font-size: 1.3rem;
        @media only screen and (min-width: $mobileToPc) {
            font-size: 1.2rem;
        }
    }
}
