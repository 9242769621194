@import "../../config/variables";
@import "../../config/buttons";

.homepage {
    font-size: 2rem;
    @media only screen and (min-width: $mobileToPc) {
        font-size: 1.8rem;
    }
    overflow: hidden;
    .ui.grid>.row>.column {
        @media only screen and (min-width: $mobileToPc) {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }
    }
    .introduction {
        padding-top: 3.7rem;
        img {
            &.ui {
                display: inline-block;
                margin-bottom: 3rem;
            }
        }
        h1 {
            font-size: 3.8rem;
            @media only screen and (min-width: $mobileToPc) {
                font-size: 3.3rem;
            }
            padding-bottom: 2rem;
        }
        strong {
            font-weight: 700;
        }
    }
    .howto {
        padding-top: 1.5rem;

        .row .column {
            margin-bottom: 4rem;
        }
        .list-item {
            padding-bottom: .21428571em;
        }

        @media only screen and (min-width: $mobileToPc) {
            padding-top: 2.5rem;

            .row .column {
                margin-bottom: 0;
            }
        }

        .title {
            font-weight: 700;
            font-size: 3.2rem;
            @media only screen and (min-width: $mobileToPc) {
                font-size: 2.8rem;
            }
            padding-bottom: 1.5rem;
        }

        ol.ui.list {
            li::before {
                content: counters(ordered, ".") ". ";
            }
            .content {
                margin-left: 1rem;
            }
        }
    }
    .forms-content {
        .forms {
            position: relative;
            padding: 3rem 0 2rem;
            margin: 5rem 0;
            & > div {
                z-index: 2;
                position: relative;
            }
            .title {
                color: $white;
                font-size: 2.2rem;
                font-weight: 700;
                text-align: center;
                padding-bottom: 2rem;
                @media only screen and (min-width: $mobileToPc) {
                    padding-bottom: 3rem;
                    font-size: 2rem;
                }
            }
            .ui.grid>.row>.column {
                width: 100%;
                @media only screen and (min-width: $mobileToPc) {
                    display: flex;
                    width: 33.3333%;
                }
            }
            .ui.card {
                border-radius: 0.5rem;
                box-shadow: 0 3px 6px 0 rgba($black,0.1), 0 4px 7px 0 rgba($black,0.06), 0 1px 5px 0 rgba($black,0.2);
                width: 100%;
                margin-bottom: 20px;
                .content {
                    position: relative;
                    @media only screen and (min-width: $mobileToPc) {
                        padding-bottom: 6.6rem;
                    }
                    @media only screen and (max-width: 991px) and (min-width: 768px) {
                        padding-bottom: 8.4rem;
                    }
                }
                img {
                    display: none;
                    @media only screen and (min-width: $mobileToPc) {
                        display: inline-block;
                    }
                }
                .header {
                    @media only screen and (min-width: $mobileToPc) {
                        padding-top: 2rem;
                    }
                }
                h2 {
                    text-transform: uppercase;
                    font-size: 2.2rem;
                    @media only screen and (min-width: $mobileToPc) {
                        font-size: 1.9rem;
                    }
                }
                .description {
                    padding-bottom: 2rem;
                    font-size: 1.8rem;
                    @media only screen and (min-width: $mobileToPc) {
                        font-size: 1.6rem;
                    }
                }
                .button {
                    @extend .button;
                    @media only screen and (min-width: $mobileToPc) {
                        position: absolute;
                        bottom: 1rem;
                        left: 0;
                        margin: 0 1rem;
                        &.large {
                            width: calc(100% - 2rem);
                        }
                    }
                }
                &:hover {
                    box-shadow: 0 0 30px 12px rgba($black,0.1), 0 4px 7px 0 rgba($black,0.06), 0 1px 5px 0 rgba($black,0.2);
                }
            }
            &:before {
                content: ' ';
                position: absolute;
                top: 0;
                left: -15%;
                width: 130%;
                height: 100%;
                transform: rotate(-3deg);
                background: linear-gradient(180.47deg, $shakespeare 0%, $cornflowerBlue 100%);
            }
        }
    }
    .description {
        .description-left {
            padding-bottom: 2rem;
            font-size: 1.6rem;
            @media only screen and (min-width: $mobileToPc) {
                padding-bottom: 0;
            }
        }
        .title {
            font-size: 2.5rem;
            @media only screen and (min-width: $mobileToPc) {
                font-size: 2.3rem;
                padding-bottom: 1rem;
            }
            font-weight: 700;
        }
        .card {
            width: 100%;
            box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 0 transparent;
            .content {
                p {
                    margin-bottom: 1em;
                    font-size: 1.6rem;
                }
                .button {
                    @extend .button;
                    font-size: 1.5rem;
                }
            }
        }
    }
    .customer--quote {
        margin: 25px 0;
        blockquote {
            font-style: italic;
        }
        p {
            text-align: right;
        }
    }
    .partnership {
        padding: 30px 0;
        @media only screen and (min-width: $mobileToPc) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        font-size: 2rem;
        font-weight: bold;
        span {
            display: block;
            padding-bottom: 2rem;
            @media only screen and (min-width: $mobileToPc) {
                display: inline-block;
                padding-right: 3rem;
                padding-bottom: 0;
            }
        }
        img {
            margin: 0 1.3rem;
            display: inline-block;
            @media only screen and (min-width: $mobileToPc) {
                display: block;
                margin-left: 0;
            }
        }
    }
}
