@import "../../config/variables";
@import "../../config/buttons";

.subtitle {
    text-align: center;
    font-weight: 700;
}

.Content {
    overflow: hidden;
}
.form-content {
    @media only screen and (min-width: $mobileToPc) {
        font-size: 1.8rem;
        .introduction {
            text-align: center;
        }
    }
    h1 {
        font-size: 3.2rem;
        color: $black;
        text-transform: uppercase;
    }
    .finalStep {
        min-height: 400px;
        .header {
            hyphens: auto;
            @media only screen and (min-width: $mobileToPc) {
                max-width: 550px;
                margin: 0 auto;
            }
        }
        .description {
            text-align: left;
            @media only screen and (min-width: $mobileToPc) {
                max-width: 445px;
                margin: 0 auto;
            }
            label {
                color: $doveGray;
                font-weight: 400;
                &.error {
                    color: $tonysPink;
                }
            }
            .ui.input {
                display: block;
                padding-bottom: 3rem;
                position: relative;
                input {
                    width: 100%;
                }
                .errorContent {
                    position: absolute;
                    bottom: 7px;
                    left: 0;
                    color: $tonysPink;
                    font-size: 1.3rem;
                }
            }
            .ui.toggle.checkbox {
                padding-bottom: 3rem;
                input~label {
                    color: $doveGray !important;
                    padding-left: 5rem;
                }
                input:checked~label,
                input:focus:checked~label {
                    &:before {
                        background-color: $chateauGreen !important;
                    }
                }
                label {
                    font-size: 1.4rem;
                    text-align: left;
                    &:before {
                        height: 1.9rem;
                        top: 2px;
                    }
                    &:after {
                        width: 1.9rem;
                        height: 1.9rem;
                        top: 2px;
                    }
                }
            }
            .button {
                @extend .finalButton;
            }
        }
    }
    .thankYou {
        padding: 3rem 0 2.5rem;
        @media only screen and (min-width: $mobileToPc) {
            padding: 4rem 0 3.5rem;
        }
        font-size: 1.8rem;
        i {
            font-size: 5rem;
            color: $easternBlue;
            @media only screen and (min-width: $mobileToPc) {
                margin-bottom: 2rem;
            }
        }
        .description {
            padding-bottom: 2rem;
            @media only screen and (min-width: $mobileToPc) {
                max-width: 420px;
                margin: 0 auto;
                padding-bottom: 3rem;
            }
        }
        .button {
            @extend .finalButton;
        }
    }
}
.loading-container,
.questions {
    min-height: 400px;
    @media only screen and (min-width: $mobileToPc) {
        min-height: 330px;
    }
    font-size: 1.6rem;
}
.ui.grid.questions {
    position: relative;
    .row {
        position: absolute;
        top: 0;
        left: 100px;
        opacity:0;
        transition-duration: 500ms;
        .next {
            display: none;
        }
        &.first {
            .previous {
                display: none;
            }
        }
        &.active {
            display: flex;
            opacity: 1;
            left: 0;
            z-index: 10;
            transition-property: opacity, left;
            .previous {
                display: inline-block;
            }
            &.first {
                .previous {
                    display: none;
                }
            }
        }
        &.end {
            transition-property: opacity;
            left: 0;
            opacity: 0;
            &.previous {
                transition-property: opacity, left;
                opacity: 1;
                left: 0;
            }
            &.active {
                left: 0;
                opacity: 1;
            }
        }
        &.last.answered {
            .next {
                display: inline-block;
            }
        }
        &.previous {
            .next {
                display: inline-block;
            }
        }
        .card {
            @media only screen and (min-width: $mobile) {
                min-width: 340px;
            }
            @media only screen and (min-width: $mobileToPc) {
                min-width: 645px;
            }
            border-radius: 5px;
            box-shadow: 0 3px 6px 0 rgba($black,0.1), 0 4px 7px 0 rgba($black,0.06), 0 1px 5px 0 rgba($black,0.2);
            .question {
                padding-bottom: 0;
            }
            .header {
                font-weight: 300;
                font-size: 1.8rem;
                padding-bottom: 2rem;
            }
            .answers,
            .actions {
                .button {
                    &:not(.icon) {
                        min-width: 124px;
                        padding: 1.4rem;
                        color: $easternBlue;
                        font-weight: 700;
                        font-size: 2rem;
                        margin: 0 1.5rem 2.7rem;
                        background-color: $catskillWhite;
                        &.primary,
                        &:hover  {
                            color: $white;
                            background-color: $easternBlue;
                        }
                    }
                    &.final {
                        @extend .finalButton;
                        font-size: 1.6rem;
                        background: white;
                        margin-bottom: .78571429em;
                        margin-right: 0;
                    }
                    &.icon {
                        background: none;
                        font-size: 1.6rem;
                        font-weight: 400;
                        i {
                            background: none;
                            width: 2rem;
                        }
                        &.left {
                            padding-left: 2.5rem !important;
                            padding-right: 0 !important;
                        }
                        &.right {
                            padding-right: 2.5rem !important;
                            padding-left: 0 !important;
                        }
                    }
                }
            }
            .actions {
                display: flex;
                justify-content: space-between;
            }
        }
        &.first {
            .card {
                .actions {
                    justify-content: flex-end;
                }
            }
        }
    }
}
.steps {
    .steper {
        max-width: 325px;
        margin: 0 auto 25px;
        height: 35px;
        position: relative;
        &:before {
            content: ' ';
            display: block;
            position: absolute;
            top: 16px;
            height: 2px;
            width: 100%;
            background-color: $gersey;
        }
    }
    .number {
        margin-left: calc((100% - (35px*4)) / 3);
        position: relative;
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 3.5rem;
        text-align: center;
        z-index: 2;
        color: $gersey;
        font-size: 2rem;
        font-weight: 700;
        &:first-child {
            margin-left: 0;
        }
        &:before {
            content: ' ';
            width: 35px;
            height: 35px;
            border-radius: 17.5px;
            background-color: $white;
            border: 1px solid $gersey;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        &.active {
            color: $white;
            &:before {
                background-color: $elm;
                border-color: $elm;
            }
        }
        &.old {
            color: $white;
            &:before {
                background-color: $gersey;
                border-color: $gersey;
            }
        }
    }
    .title-block {
        height: 35px;
        overflow: hidden;
        position: relative;
        margin-bottom: 2rem;
        .title {
            position: absolute;
            width: 100%;
            top: 35px;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 1.8rem;
            transition-duration: 0.5s;
            transition-property: top;
            text-transform: capitalize;
            .number {
                margin-right: 10px;
            }
            &.active {
                top: 0;
            }
            &.old {
                top: -35px;
            }
        }
    }
}
