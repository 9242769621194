@import "../../config/variables";

.static-page {
    @media only screen and (min-width: $mobileToPc) {
        padding: 3.5rem 4.25rem 3rem;
    }
    font-size: 1.6rem;
    .title {
        padding-bottom: 3rem;
        h1 {
            font-size: 3.8rem;
            @media only screen and (min-width: $mobileToPc) {
                font-size: 3.3rem;
            }
        }
    }
    .introduction {
        font-size: 2rem;
        @media only screen and (min-width: $mobileToPc) {
            font-size: 1.8rem;
        }
        padding-bottom: 3rem;
    }
    h2, h3, p {
        padding-bottom: 2.2rem;
        margin: 0;
    }
    h2 {
        font-size: 3.2rem;
        @media only screen and (min-width: $mobileToPc) {
            font-size: 2.8rem;
        }
    }
    h3 {
        font-size: 2.5rem;
        @media only screen and (min-width: $mobileToPc) {
            font-size: 2.3rem;
        }
    }
    a {
        color: $thundora;
        text-decoration: underline;
    }
}
